import React, { useState } from 'react'
import { Link } from 'gatsby'
import * as yup from 'yup'
import useReCaptchaForgotPassword from '../hooks/useReCaptcha/useRecaptcha-forgotPassword'
import { AuthFlowForm, AuthFlowWrapper } from '../components/views/layout'
import { Seo } from '../components/common'
import { Input } from '../components/common/form/fields'
import { useForgottenPassword, useForm } from '../hooks'
import { Paths } from '../constants/structure'
import { Alert } from '../components/common/alert/alert'
import { Severity } from '../types'

const APP_COMPANY = process.env.APP_COMPANY

const schema = yup.object().shape({
   email: yup
      .string()
      .email('Please enter a valid email')
      .required('Email field is required'),
   'g-recaptcha-response': yup.string().required('reCAPTCHA field is required'),
})

export default function ForgotPasswordPage() {
   const { error, isLoading, isSuccess, submit } = useForgottenPassword()
   const { onSubmit, validationErrors } = useForm(schema, (values) => {
      submit(values.email, recaptchToken)
   })
   const [_isChallengeSuccess, setIsChallengeSuccess] = useState<boolean>()
   const [recaptchToken, setRecaptchToken] = useState<string>()
   const { ReCAPTCHA, sitekey, recaptchaRef, size, disableRecaptcha } =
      useReCaptchaForgotPassword()

   const handleCaptchaSuccess = () => {
      const token = recaptchaRef.current.getValue()
      if (token) {
         setIsChallengeSuccess(true)
         setRecaptchToken(token)
      }
   }

   const Layout1 = () => {
      return (
         <AuthFlowWrapper
            description={
               <>
                  Enter the <strong>email address you used</strong> to register
                  with {APP_COMPANY}.
                  <br />
                  We will send an email link to where you can create a new
                  password.
               </>
            }
            heading="Forgotten password?"
         >
            <AuthFlowForm
               //disabled={(!isChallengeSuccess && !disableRecaptcha)}
               button={{
                  label: 'Send request',
               }}
               isLoading={isLoading}
               onSubmit={onSubmit}
               returnLink={<Link to={Paths.LOGIN}>Back to Login</Link>}
            >
               <Input
                  id="email"
                  label="Email"
                  error={validationErrors?.email}
                  required
                  theme="dark"
               />

               <div style={{ marginBottom: '1.5rem' }}>
                  {!disableRecaptcha && (
                     <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={sitekey}
                        size={size}
                        onChange={handleCaptchaSuccess}
                     />
                  )}

                  {validationErrors['g-recaptcha-response'] && !recaptchToken && (
                     <p
                        style={{
                           color: '#b12024',
                           fontSize: '0.9rem',
                           marginTop: '0.3rem',
                        }}
                     >
                        {validationErrors['g-recaptcha-response']}
                     </p>
                  )}
               </div>

               {error ? (
                  <Alert message={error} severity={Severity.ERROR} />
               ) : null}
            </AuthFlowForm>
         </AuthFlowWrapper>
      )
   }
   const Layout2 = () => {
      return (
         <AuthFlowWrapper
            description={
               <>
                  Thank you. You should receive an email containing further
                  instructions to reset your password
               </>
            }
            heading="Forgotten password?"
         >
            <AuthFlowForm
               returnLink={<Link to={Paths.LOGIN}>Back to Login</Link>}
            />
         </AuthFlowWrapper>
      )
   }

   return (
      <>
         <Seo title="Forgotten password" />
         {!isSuccess ? Layout1() : Layout2()}
      </>
   )
}
